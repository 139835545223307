/* eslint-disable no-use-before-define */
import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const MultiSelectAutoComplete = (props) => {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    defaultValue,
    options,
  } = props || {};

  const onValueChange = (event, newValue) => {
    onChange({
      target: {
        name: name,
        value: newValue,
      },
    });
  };

  return (
    <Autocomplete
      multiple
      size="small"
      value={value}
      onChange={onValueChange}
      options={options}
      defaultValue={defaultValue}
      freeSolo
      renderTags={(value, getTagProps) =>
        value &&
        Array.isArray(value) &&
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} name={name} />
      )}
    />
  );
};

export default MultiSelectAutoComplete;
