import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomFileUploader from "../../components/CustomFileUploader";
import DashBoardheaderButtons from "../../components/DashBoardheaderButtons";
import CustomSubmit from "../../components/CustomSubmit";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import ResponsiveTable from "../../components/ResponsiveTable";
import { Pagination } from "@material-ui/lab";
import {
  Grid,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import {
  submitNewData,
  UpdateData,
  getLogs,
  submitProgramCode,
} from "../../actions/auth";

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CCAfile: "",
      PSfile: "",
      PSfile2: "",
      loading: false,
      isUploadprogramcode: false,
      isUploadNewData: false,
      isUploadIntStatus: false,
      emptyErrThrow: false,
      ValidationErr: false,
      resStatus: false,
      notigMsg: "",
      PSfileupdated: "",
      programCodefile: "",
      severity: "",
      pageNumber: 1,
      params: { limit: 10, skip: 0 },
      intervalId: null,
    };
  }

  componentDidMount() {
    this.getLogs();
    let timer = setInterval(() => {
      this.getLogs();
    }, 5000);
    this.setState({
      intervalId: timer,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  getLogs = () => {
    const paramsUri =
      "?" +
      Object.keys(this.state.params)
        .map((key) => {
          if (Array.isArray(this.state.params[key])) {
            return this.state.params[key]
              .map((val) => `${key}=${val}`)
              .join("&");
          } else {
            return `${key}=${this.state.params[key]}`;
          }
        })
        .join("&");

    this.props.getLogs(paramsUri).then((res) => {});
  };

  onChange = (event, page) => {
    const { limit } = this.state.params;
    this.setState(
      {
        pageNumber: page,
        params: {
          ...this.state.params,
          skip: (page - 1) * limit,
        },
      },
      this.getLogs
    );
  };

  handlePSFileChange = (e) => {
    const fileName =
      (e &&
        e.target &&
        e.target.files &&
        e.target.files[0] &&
        e.target.files[0].name) ||
      "";
    const ext =
      e && e.target && e.target.value && e.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ["csv", "CSV"];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    if (isFileAllowed) {
      this.setState({
        ValidationErr: false,
        emptyErrThrow: false,
        PSfile: e.target && e.target.files && e.target.files[0],
      });
    } else {
      this.setState({ PSfile: "", ValidationErr: true }, () => {});
    }
  };

  handleCCAFileChange = (e) => {
    const fileName =
      (e &&
        e.target &&
        e.target.files &&
        e.target.files[0] &&
        e.target.files[0].name) ||
      "";
    const ext =
      e && e.target && e.target.value && e.target.value.match(/\.([^\.]+)$/)[1];

    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ["csv", "CSV"];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    if (isFileAllowed) {
      this.setState({
        emptyErrThrow: false,
        ValidationErr: false,

        CCAfile: e.target && e.target.files && e.target.files[0],
      });
    } else {
      this.setState({
        CCAfile: "",
        ValidationErr: true,
      });
    }
  };

  handlePS2FileChange = (e) => {
    const fileName =
      (e &&
        e.target &&
        e.target.files &&
        e.target.files[0] &&
        e.target.files[0].name) ||
      "";
    const ext =
      e && e.target && e.target.value && e.target.value.match(/\.([^\.]+)$/)[1];

    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ["csv", "CSV"];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    if (isFileAllowed) {
      this.setState({
        emptyErrThrow: false,
        ValidationErr: false,

        PSfile2: e.target && e.target.files && e.target.files[0],
      });
    } else {
      this.setState({
        PSfile2: "",
        ValidationErr: true,
      });
    }
  };

  handleIntPSFileChange = (e) => {
    const fileName =
      (e &&
        e.target &&
        e.target.files &&
        e.target.files[0] &&
        e.target.files[0].name) ||
      "";
    const ext =
      e && e.target && e.target.value && e.target.value.match(/\.([^\.]+)$/)[1];

    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ["csv", "CSV"];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    if (isFileAllowed) {
      e.preventDefault();
      this.setState({
        emptyErrThrow: false,
        ValidationErr: false,

        PSfileupdated: e.target && e.target.files && e.target.files[0],
      });
    } else {
      this.setState({
        PSfileupdated: "",
        ValidationErr: true,
      });
    }
  };

  handleprogramcodeFileChange = (e) => {
    const fileName =
      (e &&
        e.target &&
        e.target.files &&
        e.target.files[0] &&
        e.target.files[0].name) ||
      "";

    const ext =
      e && e.target && e.target.value && e.target.value.match(/\.([^\.]+)$/)[1];
    const formattedExt = ext.toString().toLowerCase();
    const allowedTypes = ["csv", "CSV"];
    const isFileAllowed = allowedTypes.includes(formattedExt);
    if (isFileAllowed) {
      e.preventDefault();
      this.setState({
        ValidationErr: false,
        emptyErrThrow: false,
        programCodefile: e.target && e.target.files && e.target.files[0],
      });
    } else {
      this.setState({
        programCodefile: "",
        ValidationErr: true,
      });
    }
  };
  reset = () => {
    this.setState({
      emptyErrThrow: false,
      ValidationErr: false,
      isUploadNewData: false,
      isUploadIntStatus: false,
      isUploadprogramcode: false,
      programCodefile: "",
      PSfileupdated: "",
      CCAfile: "",
      PSfile: "",
      PSfile2: "",
    });
  };

  handleClose = () => {
    this.setState({
      isUploadIntStatus: false,
      isUploadNewData: false,
      isUploadprogramcode: false,
      emptyErrThrow: false,
      ValidationErr: false,
      programCodefile: "",
      PSfileupdated: "",
      CCAfile: "",
      PSfile: "",
      PSfile2: "",
    });
  };

  handleSubmitNewData = (e) => {
    const { CCAfile, PSfile, PSfile2 } = this.state;
    if (
      (CCAfile === "" || CCAfile === undefined) &&
      (PSfile === "" || PSfile === undefined) &&
      (PSfile2 === "" || PSfile2 === undefined)
    ) {
      this.setState(
        {
          emptyErrThrow: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              emptyErrThrow: false,
            });
          }, 5000);
        }
      );
      return;
    } else {
      this.setState({
        isUploadNewData: false,
      });
    }
    const data = new FormData();
    if (PSfile && PSfile instanceof File) {
      data.append("PSfile", PSfile);
    }
    if (CCAfile && CCAfile instanceof File) {
      data.append("CCAfile", CCAfile);
    }
    if (PSfile2 && PSfile2 instanceof File) {
      data.append("PSfile2", PSfile2);
    }
    this.props
      .submitNewData(data)
      .then((res) => {
        this.reset();
        if (res && res.message === "Success") {
          this.getLogs();
          this.setState({
            resStatus: true,
            notigMsg: "uploaded Successfully",
            severity: "success",
          });
        } else {
          this.setState({
            resStatus: true,
            severity: "error",
            notigMsg: "Failed to upload",
          });
        }
      })
      .catch((res) => {
        this.reset();
        this.setState({
          resStatus: true,
          severity: "error",
          notigMsg: `Failed to upload due to ${res.message} `,
        });
      });
  };

  handleSubmitIntData = () => {
    const { PSfileupdated } = this.state;
    if (PSfileupdated === "" || PSfileupdated === undefined) {
      this.setState(
        {
          emptyErrThrow: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              emptyErrThrow: false,
            });
          }, 5000);
        }
      );
      return;
    } else {
      this.setState({
        isUploadIntStatus: false,
      });
    }
    const data = new FormData();
    data.append("file", PSfileupdated);
    this.props
      .UpdateData(data)
      .then((res) => {
        this.reset();
        if (res && res.message === "Success") {
          this.getLogs();
          this.setState({
            resStatus: true,
            severity: "success",
            notigMsg: "uploaded Successfully",
          });
        } else {
          this.setState({
            resStatus: true,
            notigMsg: "Failed to upload",
            severity: "error",
          });
        }
      })
      .catch((res) => {
        this.reset();
        this.setState({
          resStatus: true,
          severity: "error",
          notigMsg: `Failed to upload due to ${res.message} `,
        });
      });
  };

  handleSubmitProgramCode = () => {
    const { programCodefile } = this.state;
    if (programCodefile === "" || programCodefile === undefined) {
      this.setState(
        {
          emptyErrThrow: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              emptyErrThrow: false,
            });
          }, 5000);
        }
      );
      return;
    } else {
      this.setState({
        isUploadIntStatus: false,
      });
    }
    const data = new FormData();
    data.append("file", programCodefile);
    this.props
      .submitProgramCode(data)
      .then((res) => {
        if (res && res.message === "Success") {
          this.reset();
          this.getLogs();
          this.setState({
            resStatus: true,
            severity: "success",
            notigMsg: "uploaded Successfully",
          });
        } else {
          this.setState({
            resStatus: true,
            notigMsg: "Failed to upload",
            severity: "error",
          });
        }
      })
      .catch((res) => {
        this.reset();
        this.setState({
          resStatus: true,
          severity: "error",
          notigMsg: `Failed to upload due to ${res.message} `,
        });
      });
  };

  handleUploadNewData = () => {
    this.setState({
      isUploadNewData: true,
      isUploadIntStatus: false,
      isUploadprogramcode: false,
    });
  };
  handleUploadIntStatus = () => {
    this.setState({
      isUploadIntStatus: true,
      isUploadNewData: false,
      isUploadprogramcode: false,
    });
  };

  handleUploadprogramcode = () => {
    this.setState({
      isUploadIntStatus: false,
      isUploadNewData: false,
      isUploadprogramcode: true,
    });
  };
  handleSnakbarClose = () => {
    this.setState({
      resStatus: false,
    });
  };

  render() {
    const {
      isUploadNewData,
      isUploadIntStatus,
      isUploadprogramcode,
      CCAfile,
      PSfile,
      PSfile2,
      emptyErrThrow,
      ValidationErr,
      resStatus,
      notigMsg,
      severity,
      PSfileupdated,
      programCodefile,
      pageNumber,
    } = this.state;
    const { logsData, isLoading, count } = this.props;

    const logRows = logsData
      ? logsData.map((obj, key) => ({
          id: key,
          date: obj.createdAt,
          filename: obj.fileName,
          status: obj.status,
          message: obj.eventName,
          failedRows: obj.failedRows,
        }))
      : [];

    let noContentBox = false;
    if (logRows.length > 0) {
      noContentBox = false;
    } else {
      noContentBox = true;
    }

    const columns = [
      {
        field: "",
        headerName: "",
      },
      {
        field: "timestamp",
        headerName: "Timestamp",
      },
      {
        field: "filename",
        headerName: "File Name",
      },
      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "message",
        headerName: "Message",
      },
    ];

    return (
      <Grid container>
        {" "}
        <Snackbar
          open={resStatus}
          autoHideDuration={5000}
          onClose={this.handleSnakbarClose}
        >
          <Alert
            variant="filled"
            onClose={this.handleSnakbarClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {notigMsg}
          </Alert>
        </Snackbar>
        <Grid item xs={12}>
          <Card
            style={{
              border: "1px solid #adacad",
              color: "#2b2b2b",
              borderRadius: 5,
              height: "auto",
              marginTop: "-10px",
            }}
          >
            <CardContent>
              <div
                style={{
                  marginBottom: "25px",
                }}
              >
                <DashBoardheaderButtons
                  handleUploadNewData={this.handleUploadNewData}
                  handleUploadIntStatus={this.handleUploadIntStatus}
                  handleUploadprogramcode={this.handleUploadprogramcode}
                />
              </div>
              <Dialog
                open={isUploadNewData}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                height="75%"
                borderRadius={5}
              >
                <DialogTitle id="alert-dialog-title">Upload Files </DialogTitle>
                {emptyErrThrow && (
                  <Alert severity="error">Please select a file </Alert>
                )}
                {ValidationErr && (
                  <Alert severity="error">
                    Only .CSV file type is allowed, please upload a .CSV file.{" "}
                  </Alert>
                )}
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Grid item>
                        <CustomFileUploader
                          Label="PS"
                          handleFileChange={this.handlePSFileChange}
                          chosenFileName={PSfile}
                        />{" "}
                      </Grid>

                      <Grid item>
                        {/* This is Generic file uploader which can be reused  */}

                        <CustomFileUploader
                          handleFileChange={this.handleCCAFileChange}
                          Label="CCA"
                          chosenFileName={CCAfile}
                        />
                      </Grid>

                      <Grid item>
                        <CustomFileUploader
                          Label="APPRAISAL"
                          handleFileChange={this.handlePS2FileChange}
                          chosenFileName={PSfile2}
                        />
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <CustomSubmit
                    handleSubmit={this.handleSubmitNewData}
                    loading={false}
                    reset={this.reset}
                  />
                </DialogActions>
              </Dialog>

              <Dialog
                open={isUploadIntStatus}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                height="75%"
                borderRadius={7}
              >
                <DialogTitle id="alert-dialog-title">Upload Files </DialogTitle>
                {emptyErrThrow && (
                  <Alert severity="error">Please select a file </Alert>
                )}
                {ValidationErr && (
                  <Alert severity="error">
                    Only .CSV file type is allowed, please upload a .CSV file.{" "}
                  </Alert>
                )}

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Grid item>
                        <CustomFileUploader
                          Label="Update Interview Status"
                          handleFileChange={this.handleIntPSFileChange}
                          chosenFileName={PSfileupdated}
                        />
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <CustomSubmit
                    handleSubmit={this.handleSubmitIntData}
                    loading={false}
                    reset={this.reset}
                  />
                </DialogActions>
              </Dialog>

              <Dialog
                open={isUploadprogramcode}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                height="75%"
                borderRadius={7}
              >
                <DialogTitle id="alert-dialog-title">Upload Files </DialogTitle>
                {emptyErrThrow && (
                  <Alert severity="error">Please select a file </Alert>
                )}
                {ValidationErr && (
                  <Alert severity="error">
                    Only .CSV file type is allowed, please upload a .CSV file.{" "}
                  </Alert>
                )}

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <Grid item>
                        <CustomFileUploader
                          Label="Programme Code"
                          handleFileChange={this.handleprogramcodeFileChange}
                          chosenFileName={programCodefile}
                        />
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <CustomSubmit
                    handleSubmit={this.handleSubmitProgramCode}
                    loading={false}
                    reset={this.reset}
                  />
                </DialogActions>
              </Dialog>
            </CardContent>

            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={12}>
                <div
                  style={{
                    marginTop: "12px",
                    height: 500,
                    justifyContent: "center",
                    margin: "25px",
                  }}
                >
                  <ResponsiveTable
                    rows={logRows}
                    columns={columns}
                    noContentBox={noContentBox}
                    isLoading={isLoading}
                    pageNumber={pageNumber}
                  />
                  {!noContentBox && (
                    <Pagination
                      count={Math.ceil(count / 10)}
                      page={pageNumber}
                      onChange={this.onChange}
                      style={{ float: "right", marginTop: 7, marginBottom: 7 }}
                      variant="outlined"
                      color="primary"
                      shape="rounded"
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  logsData: state.auth.logsData,
  isLoading: state.auth.isLoading,
  count: state.auth.count,
});

export default connect(mapStateToProps, {
  submitNewData,
  UpdateData,
  submitProgramCode,
  getLogs,
})(withRouter(UserDashboard));
