//generic
export const REQUEST_USER_LOGIN = "REQUEST_USER_LOGIN";
export const SUCCESS_USER_LOGIN = "SUCCESS_USER_LOGIN";
export const ERROR_USER_LOGIN = "ERROR_USER_LOGIN";
export const LOGOUT = "LOGOUT";
export const REQUEST_UPLOAD_NEW_FILE = "REQUEST_UPLOAD_NEW_FILE";
export const SUCCESS_UPLOAD_NEW_FILE = "SUCCESS_UPLOAD_NEW_FILE";
export const ERROR_UPLOAD_NEW_FILE = "ERROR_UPLOAD_NEW_FILE";
export const REQUEST_UPDATE_DATA = "REQUEST_REQUEST_UPDATE_DATA";
export const SUCCESS_REQUEST_UPDATE_DATA = "SUCCESS_REQUEST_UPDATE_DATA";
export const ERROR_REQUEST_UPDATE_DATA = "ERROR_REQUEST_UPDATE_DATA";
export const REQUEST_LOGS = "REQUEST_LOGS";
export const SUCCESS_LOGS = "SUCCESS_LOGS";
export const ERROR_LOGS = "ERROR_LOGS";
export const REQUEST_PROGRAM_CODE = "REQUEST_PROGRAM_CODE";
export const SUCCESS_PROGRAM_CODE = "SUCCESS_PROGRAM_CODE";
export const ERROR_PROGRAM_CODE = "ERROR_PROGRAM_CODE";
