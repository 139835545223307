import "./AuthLayout.css";
import React, { useState, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authFlatRoutes as authRoutes } from "../routes/AuthRoutes";
import PrivateRoute from "../components/PrivateRoute";
import { logout } from "../actions/auth";
import clsx from "clsx";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import ntulogo from "../assets/images/ntulogo.png";

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Avatar,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import BreadCrumbs from "../components/BreadCrumbs";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  logo: {
    // margin: "2px 2px",
    // padding: "5px 20px",
    height: "12px",
    width: "199px",
    margin: "0 auto",
    background: "white",
  },

  menuItemRoot: {
    "&:hover": {
      background: "#4c65b0",
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#162759",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#162759",
  },
  appBarShiftInitial: {
    width: `100%`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#162759",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#ffffff",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    background: "#162759",
    color: "#F6F6F6",
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#162759",
    color: "#F6F6F6",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  contentInitial: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#F6F6F6",
    minHeight: "100vh",
    maxWidth: "100%",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    background: "#F6F6F6",
    minHeight: "100vh",
    maxWidth: "100%",
    overflowX: "hidden",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#F6F6F6",
    minHeight: "100vh",
    maxWidth: "100%",
    overflowX: "hidden",
  },
}));

const AuthLayout = (props) => {
  const classes = useStyles();
  const userId = props.auth.user.userId;
  const user = props.auth.user;

  const [openMenu, setOpenMenu] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(
    isWidthUp("sm", props.width) ? true : false
  );

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleAccount = (event) => {
    props.history.push("/account");
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <PrivateRoute
          exact
          path={prop.path}
          name={prop.name}
          component={prop.component}
          key={key}
          data-tour={prop.step}
        />
      );
    });
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer,
          [classes.appBarShiftInitial]: !openDrawer,
        })}
        color="default"
      >
        <Toolbar>
          {userId ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggleDrawer}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <span style={{ display: "flex", flexGrow: 1 }}>
            {!openDrawer && (
              <img
                src={ntulogo}
                mode="inline"
                alt="company logo"
                style={{
                  padding: "5px 20px",
                  width: "190px ",
                  margin: 0,
                  marginRight: "22px",
                  background: "white",
                }}
                height="46px"
                width="128px"
              />
            )}
          </span>
          <div>
            <IconButton
              ref={anchorRef}
              aria-controls={openMenu ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              edge="end"
              color="inherit"
              onClick={handleToggle}
              id="user"
              data-tour="ninth-step"
            >
              <Avatar alt="Profile" src={user.picture}>
                {user.firstName[0]}
              </Avatar>
            </IconButton>
            <Popper
              open={openMenu}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper elevation={3}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={openMenu}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {userId && (
                          <MenuItem onClick={handleAccount}>
                            Dashboard{" "}
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            props.logout();
                            props.history.push("/signin");
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      {userId ? (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          data-tour="first-step"
        >
          <div className={clsx(classes.drawerHeader, classes.logo)}>
            <img
              src={ntulogo}
              mode="inline"
              alt="company logo"
              style={{
                padding: "5px 20px",
                width: "190px ",
                margin: "0 auto",
                marginRight: "10px",
              }}
            />
          </div>
          <Divider />
          <List>
            {authRoutes.map((prop, key) => {
              if (prop.sidebar === true) {
                return (
                  <ListItem
                    button
                    key={key}
                    data-tour={prop.step}
                    onClick={() => props.history.push(prop.path)}
                    classes={{
                      root: classes.menuItemRoot,
                    }}
                  >
                    <ListItemIcon
                      style={{ minWidth: "40px", color: "#fff" }}
                      color="inherit"
                    >
                      {prop.icon}
                    </ListItemIcon>
                    <ListItemText primary={prop.name} />
                  </ListItem>
                );
              }
            })}
          </List>
        </Drawer>
      ) : null}
      <main
        className={clsx(null, {
          [classes.content]: !openDrawer,
          [classes.contentShift]: openDrawer,
          [classes.contentInitial]: !openDrawer,
        })}
      >
        <div className={classes.drawerHeader} />
        <BreadCrumbs />
        <Switch>
          {getRoutes(authRoutes)}
          <Redirect to="/dashboard" />
        </Switch>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withWidth()(AuthLayout));
