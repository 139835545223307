import Logout from "../containers/auth/Logout";
import UserDashboard from "../containers/auth/UserDashboard";
import GlobalView from "../containers/auth/GlobalView";
import PowerBiReports from "../containers/auth/PowerBiReports";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const combinePaths = (parent, child) =>
  `${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;

const buildPaths = (navigation, parentPath = "") =>
  navigation.map((route) => {
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

const setupParents = (routes, parentRoute = null) =>
  routes.map((route) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

const flattenRoutes = (routes) =>
  routes
    .map((route) => [route.routes ? flattenRoutes(route.routes) : [], route])
    .flat(Infinity);

export const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: UserDashboard,
    sidebar: true,
    icon: <HomeOutlinedIcon />,
  },
   {
    path: "/globalview",
    name: "GlobalView",
    component: GlobalView,
    sidebar: true,
    icon: <PublicOutlinedIcon />,
  },
  {
    path: "/reports",
    name: "Power BI reports",
    component: PowerBiReports,
    sidebar: true,
    icon: <DashboardOutlinedIcon />,
  },
  {
    path: "/Logout",
    name: "Logout",
    component: Logout,
    sidebar: true,
    icon: <LogoutOutlinedIcon />,
  },
];

export const authRoutes = flattenRoutes(setupParents(buildPaths(routes)));

export const authFlatRoutes = flattenRoutes(buildPaths(routes));
