import React from "react";
import { Grid, Button } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";

function CustomSubmit({ handleSubmit, loading, reset }) {
  return (
    <div
      style={
        {
          // marginTop: "-20px",
        }
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={{
            display: "flex",
            marginBottom: "12px",
            marginTop: "-15px",
          }}
        >
          {" "}
          <Grid item>
            <Button
              style={{
                marginRight: "15px",
                color: "#162759",
                // width: "25%",
              }}
              variant="contained"
              color="#162759"
              onClick={reset}
              size="small"
            >
              Cancel
            </Button>
          </Grid>{" "}
          <Grid item>
            <LoadingButton
              style={{
                marginRight: "12px",
                // width: "20%",
              }}
              onClick={handleSubmit}
              loading={loading}
              loadingIndicator="Loading..."
              variant="contained"
              size="small"
            >
              Submit
            </LoadingButton>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default CustomSubmit;
