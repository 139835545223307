import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Signin from "./containers/public/Signin";
import AuthLayout from "./layouts/AuthLayout";

import { connect } from "react-redux";
import { CssBaseline, Container } from "@material-ui/core";

import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { fade } from "./styles/colorManipulator";

const font = "'Manrope', sans-serif";

let theme = createMuiTheme((theme) => ({
  overrides: {
    MuiButton: {
      /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
      textPrimary: {
        color: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: fade(
            theme.palette.primary.main,
            theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      },
      /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
      textSecondary: {
        color: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: fade(
            theme.palette.secondary.main,
            theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        padding: "5px 15px",
        border: `1px solid ${
          theme.palette.type === "light"
            ? "rgba(0, 0, 0, 0.23)"
            : "rgba(255, 255, 255, 0.23)"
        }`,
        "&$disabled": {
          border: `1px solid ${theme.palette.action.disabledBackground}`,
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
      outlinedPrimary: {
        color: theme.palette.primary.main,
        border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
        "&:hover": {
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: fade(
            theme.palette.primary.main,
            theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
      outlinedSecondary: {
        color: theme.palette.secondary.main,
        border: `1px solid ${fade(theme.palette.secondary.main, 0.5)}`,
        "&:hover": {
          border: `1px solid ${theme.palette.secondary.main}`,
          backgroundColor: fade(
            theme.palette.secondary.main,
            theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
        "&$disabled": {
          border: `1px solid ${theme.palette.action.disabled}`,
        },
      },
      /* Styles applied to the root element if `variant="contained"`. */
      contained: {
        color: theme.palette.getContrastText(theme.palette.grey[300]),
        backgroundColor: theme.palette.grey[300],
        boxShadow: theme.shadows[2],
        "&:hover": {
          backgroundColor: theme.palette.grey.A100,
          boxShadow: theme.shadows[4],
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            boxShadow: theme.shadows[2],
            backgroundColor: theme.palette.grey[300],
          },
          "&$disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        "&$focusVisible": {
          boxShadow: theme.shadows[6],
        },
        "&:active": {
          boxShadow: theme.shadows[8],
        },
        "&$disabled": {
          color: theme.palette.action.disabled,
          boxShadow: theme.shadows[0],
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
      containedPrimary: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
      /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
      containedSecondary: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: theme.palette.secondary.dark,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      },
    },
  },
}));

function App(props) {
  const { token } = props.auth || {};

  theme = createMuiTheme({
    palette: {
      type: "light",
      primary: {
        light: "#5e92f3",
        main: "#1565c0",
        dark: "#003c8f",
        contrastText: "#fff",
      },
      secondary: {
        light: "#aa5894",
        main: "#792A66",
        dark: "#4a003b",
        contrastText: "#fff",
      },
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: font,
      button: {
        textTransform: "none",
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        {token ? (
          <Switch>
            <Route path="/" render={(props) => <AuthLayout {...props} />} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/" render={(props) => <Signin {...props} />} />
          </Switch>
        )}
      </Router>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(App);
