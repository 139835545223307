import "./Signin.css";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
} from "@material-ui/core";
import { Link, Route, Switch, Redirect } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    flexGrow: 1,
    color: "#fff",
  },
  toolbar: theme.mixins.toolbar,
  list: {
    width: 250,
    background: "#4a2040",
    color: "#fff",
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    background: "#4a2040",
    color: "#fff",
  },
  navButton: {
    color: "#fff",
    "&:hover": {
      transition: "0.3s",
      backgroundColor: "#f1e2ec",
      color: "#000",
    },
  },
}));

const CustomHeader = (props) => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" style={{ background: "#162759" }}>
          <Toolbar>
            <Typography variant="h6" className={classes.logo}>
              <div className="logo">
                <img
                  src="https://www.ntu.edu.sg/images/default-source/corporate/ntu-30-anniversary-logoc1850609-db51-4cc8-9788-62c50778e47b.svg?sfvrsn=5b13ef08_3"
                  mode="inline"
                  alt="company logo"
                  style={{
                    width: "100%",
                    height: 55,
                    background: "white",
                    padding: "3px 5px",
                    // margin: "-10px",
                    marginRight: "22px",
                  }}
                />
              </div>
            </Typography>
            {/* {windowWidth > 600 ? (
              <>
                <Button
                  className={classes.navButton}
                  variant="text"
                  onClick={() => props.history.push("/")}
                >
                  Home
                </Button>
                <Button
                  className={classes.navButton}
                  onClick={() => props.history.push("/user-signup")}
                >
                  Sign Up
                </Button>
                <Button
                  className={classes.navButton}
                  onClick={() => props.history.push("/signin")}
                >
                  Sign In
                </Button>{" "}
              </>
            ) : (
              <>
                <Button color="inherit" onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </Button>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  classes={{
                    paper: classes.drawer,
                  }}
                >
                  <div
                    className={classes.list}
                    style={{ background: "#4a2040" }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <List style={{ background: "#4a2040" }}>
                      <ListItem>
                        <Button color="primary">
                          <Link
                            to="/"
                            style={{ color: "#fff", textDecoration: "none" }}
                          >
                            Home
                          </Link>
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button>
                          <Link
                            to="/user-signup"
                            style={{ color: "#fff", textDecoration: "none" }}
                          >
                            Sign Up
                          </Link>
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button color="inherit">
                          <Link
                            to="/signin"
                            style={{ color: "#fff", textDecoration: "none" }}
                          >
                            Sign In
                          </Link>
                        </Button>
                      </ListItem>
                    </List>
                  </div>
                </Drawer>
              </>
            )} */}
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default CustomHeader;
