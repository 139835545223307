const appendTokenToReq = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const xrt = searchParams.get("xrt");
  let userId = -1;
  const vl = xrt && xrt.split("_");
  if (Array.isArray(vl) && vl.length === 5) {
    userId = vl[2];
  }
  const xrdata = {
    userId,
    token: xrt,
  };
  return xrdata;
};

export { appendTokenToReq };
