import fetch from "cross-fetch";
import React from "react";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const AutocompleteWithAddField = (props) => {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    defaultValue,
    onBlur,
    enableAdd,
    options,
    onInputChange,
  } = props;
  const [open, setOpen] = React.useState(false);

  const loading = open && options.length === 0;

  const onTextInputChange = (e, newValue) => {
    // setInputValue(newValue);
    onInputChange(name, newValue);
  };
  const filter = createFilterOptions();

  const onValueChange = (event, newValue) => {
    if (typeof newValue === "string") {
      onChange({
        target: {
          name: name,
          value: newValue ? newValue[name] : "",
        },
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      onChange({
        target: {
          name: name,
          value: newValue.inputValue,
        },
      });
    } else {
      onChange({
        target: {
          name: name,
          value: newValue ? newValue[name] : "",
        },
      });
    }
  };

  return (
    <Autocomplete
      value={value}
      options={options}
      onChange={onValueChange}
      size="small"
      onInputChange={onTextInputChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== "" && enableAdd) {
          filtered.push({
            inputValue: params.inputValue,
            [name]: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option[name];
      }}
      renderOption={(option) => option[name]}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={label} name={name} variant="outlined" />
      )}
      onBlur={onBlur}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(AutocompleteWithAddField);
