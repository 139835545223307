import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Empty from "./Empty";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardContent } from "@material-ui/core";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function CustomizedTables({
  rows,
  columns,
  noContentBox,
  pageNumber,
  isLoading,
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="collapsible table">
        <TableHead>
          {columns &&
            columns.map((obj, idx) => {
              const { headerName } = obj || {};
              return (
                <>
                  <TableCell>
                    <b>{headerName}</b>
                  </TableCell>
                </>
              );
            })}
        </TableHead>
        <TableBody>
          {rows.map(
            (row) => (
              <Row key={row.id} row={row} rows={rows} pageNumber={pageNumber} />
            )
            /* <>
              <StyledTableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                id={row.id}
                key={row.id}
              >
                <StyledTableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {moment(row.date).format("MMMM Do YYYY, h:mm:ss a")}
                </StyledTableCell>
                <StyledTableCell>{row.filename}</StyledTableCell>
                <StyledTableCell>
                  <Chip label={row.status} color="success" variant="outlined" />  uncomment this if neede bg color for status text
                  {row.status}
                </StyledTableCell>
                <StyledTableCell>{row.message}</StyledTableCell>
              </StyledTableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        History
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Total price ($)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {row.status}
                            </TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.status}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </> */
          )}
        </TableBody>
      </Table>
      {noContentBox && (
        <Card
          style={{
            borderRadius: 5,
            height: 580,
          }}
        >
          <CardContent
            style={{
              marginTop: "150px",
            }}
          >
            <Empty message="No Logs" />
          </CardContent>
        </Card>
      )}
    </TableContainer>
  );
}

function Row({ rows, row, columns, count, pageNumber }) {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(false);
  }, [pageNumber]);

  return (
    <>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }} key={row.id}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {moment(row.date).format("MMMM Do YYYY, h:mm:ss a")}
        </StyledTableCell>
        <StyledTableCell>{row.filename}</StyledTableCell>
        {/* <Chip label={row.status} color="success" variant="outlined" /> uncomment
        this if neede bg color for status text */}
        <StyledTableCell>{row.status}</StyledTableCell>
        <StyledTableCell>{row.message}</StyledTableCell>
        <StyledTableCell>{row.uploadeduser}</StyledTableCell>
      </StyledTableRow>
      {/* accordian */}
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit colSpan={6}>
            <Box sx={{ marginLeft: 10, marginTop: 2, marginBottom: 0 }}>
              {row && row.failedRows && row.failedRows.length > 0 ? (
                row.failedRows.map((obj, idx) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell>{obj}</TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <div
                    style={{
                      marginBottom: 12,
                      marginLeft: 10,
                    }}
                  >
                    {row.message}{" "}
                  </div>
                </TableRow>
              )}
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
