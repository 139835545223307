import React from "react";
import X0PALogo from "./../assets/images/x0pa-purple.png";
import "./PublicFooter.css";

const PublicFooter = (props) => {
  const { handleRedirectMainPage, currProd, hideTile } = props || {};
  return (
    <div
      className={`PublicJobInfoFooter p2 w-100 xpa-footer xpa-d-flex ${
        hideTile ? "" : "bx--tile"
      }`}
    >
      <div
        className=""
        onClick={() => {
          handleRedirectMainPage(currProd || "roboroy");
        }}
        href="javascript:void(0)"
        prefix=""
      >
        <img className="xpa-top-header--logo-room" src={X0PALogo} alt="logo" />
      </div>
      <div className="" style={{ display: "flex", alignItems: "center" }}>
        <div className="">
          <a href="https://x0pa.com/terms-of-use/">Terms of use</a>
        </div>
        <div className="ml2">
          <a href="https://x0pa.com/privacypolicy/">Privacy policy</a>
        </div>
        <div className="ml2">
          <a href="https://x0pa.com/cookiespolicy/">Cookies policy</a>
        </div>
      </div>
    </div>
  );
};

export default PublicFooter;
