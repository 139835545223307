import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Home from "../containers/public/Home";
import Inactive from "../containers/auth/Inactive";

const PrivateRoute = ({ component: Component, auth, name, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (auth.user) {
        if (auth.user.active || name === "Email Verification") {
          return <Component {...props} {...rest} />;
        } else {
          return;
        }
      } else {
        return <Home />;
      }
    }}
  />
);

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
