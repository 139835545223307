import callApi from "../utils/callApi";
import { baseURL } from "../utils/config";
import {
  REQUEST_USER_LOGIN,
  LOGOUT,
  REQUEST_UPLOAD_NEW_FILE,
  REQUEST_UPDATE_DATA,
  REQUEST_PROGRAM_CODE,
  REQUEST_LOGS,
} from "./types";

export const login = (data) => (dispatch) => {
  const { xctx, xn, ...xdata } = data ||{};
  const url = `${baseURL}/login`;
  const params = {
    headers: {
      "content-type": "application/json",
      "x-xct": xctx,
      "x-xno": xn
    },
    dispatch,
    method: "POST",
    url,
    actionType: REQUEST_USER_LOGIN,
    body: JSON.stringify(xdata),
  };

  return callApi(params);
};

export const submitNewData = (data) => (dispatch, getState) => {
  const url = `${baseURL}/application`;
  const params = {
    headers: {},
    dispatch,
    method: "POST",
    url,
    actionType: REQUEST_UPLOAD_NEW_FILE,
    body: data,
  };
  const token = getState().auth.token;
  if (token) {
    params.headers["Authorization"] = `Bearer ${token}`;
  }
  return callApi(params);
};

export const submitProgramCode = (data) => (dispatch, getState) => {
  const url = `${baseURL}/application/acad`;
  const params = {
    headers: {},
    dispatch,
    method: "POST",
    url,
    actionType: REQUEST_PROGRAM_CODE,
    body: data,
  };
  const token = getState().auth.token;
  if (token) {
    params.headers["Authorization"] = `Bearer ${token}`;
  }
  return callApi(params);
};

export const UpdateData = (data) => (dispatch, getState) => {
  const url = `${baseURL}/application/update`;
  const params = {
    headers: {},
    dispatch,
    method: "POST",
    url,
    actionType: REQUEST_UPDATE_DATA,
    body: data,
  };
  const token = getState().auth.token;
  if (token) {
    params.headers["Authorization"] = `Bearer ${token}`;
  }
  return callApi(params);
};

export const getLogs = (paramsUri) => (dispatch, getState) => {
  let url = "";
  if (paramsUri) url = `${baseURL}/log${paramsUri}`;
  else url = `${baseURL}/log`;
  const params = {
    headers: {
      "content-type": "application/json",
    },
    dispatch,
    method: "GET",
    url,
    actionType: REQUEST_LOGS,
  };
  const token = getState().auth.token;
  if (token) {
    params.headers["Authorization"] = `Bearer ${token}`;
  }
  return callApi(params);
};

export const logout = (data) => (dispatch, getState) => {
  const url = `${baseURL}/logout`;
  const params = {
    headers: {
      "content-type": "application/json",
    },
    dispatch,
    method: "POST",
    url,
    actionType: LOGOUT,
    body: JSON.stringify(data),
  };
  const token = getState().auth.token;

  if (token) {
    params.headers["Authorization"] = `Bearer ${token}`;
  }
  return callApi(params);
};

export const getRText = () => (dispatch) => {
  const url = `${baseURL}/login/x/t`;
  const params = {
    headers: {
      "content-type": "application/json",
      "x-cl": "ntu",
      "x-xnd": Date.now(),
    },
    dispatch,
    method: "GET",
    url,
    actionType: 'REQUEST_R_TEXT',
  };

  return callApi(params);
};