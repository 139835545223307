import React, { useState, useEffect, useRef } from "react";
import { InputLabel, FormControl, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./richtext.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
    width: 300,
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const RichText = (props) => {
  const classes = useStyles();
  const { name, label, value, onChange, error } = props;
  const prevValue = usePrevious(value);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    onChange({
      target: {
        name,
        value: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      },
    });
  };

  useEffect(() => {
    if (!prevValue && value) {
      const html = value;
      const contentBlock = html && htmlToDraft(html);
      let finalDescription = EditorState.createEmpty();
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        finalDescription = EditorState.createWithContent(contentState);
      }
      setEditorState(finalDescription);
    }
  }, [value]);

  return (
    <FormControl className={clsx(classes.margin)} margin="normal" {...props}>
      <label
        style={{
          fontSize: "1.1rem",
          color: "rgba(0, 0, 0, 0.5)",
          marginBottom: 5,
        }}
      >
        {label}
      </label>

      <Editor
        editorState={editorState}
        editorClassName="editor-class"
        name={name}
        onEditorStateChange={onEditorStateChange}
      />
    </FormControl>
  );
};

export default RichText;
