import React, { useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { login, getRText } from "../../actions/auth";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { enc } from "crypto-js/core";
import AES from "crypto-js/aes";
import Fields from "../../components/fields/Fields";
import { useForm, Form } from "../../components/Form";
import ReCAPTCHA from "react-google-recaptcha";
import CustomHeader from "./CustomHeader";
import { useEffect } from "react";
import PublicFooter from "../../components/PublicFooter";

const Signin = (props) => {
  const [rObj, setRtext] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const { isLoading } = props.auth;
  const { getRText } = props || {};
  const _reCaptchaRef = useRef();
  const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
    rememberMe: false,
    captcha: null,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    getRText()
      .then((res) => {
        const { xt } = res || {};
        if(xt) {
          setRtext(res);
        }
      })
      .catch((error) => {
        console.error(error);
        setRtext(null);
        setServerErrors({
          message: "Unable to authorize",
        });
      });
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const { email, password, rememberMe, captcha } = values;
      const user_data = {
        email,
        password,
        // rememberMe,
        captcha,
      };
      const { xt, xn } = rObj || {};
      if(!xt) {
        alert("Please reload the page");
        return;
      }
      const ctext = AES.encrypt(JSON.stringify(user_data), xt).toString();
      props
        .login({
          xt: ctext,
          xctx: xt,
          xn,
        })
        .then((res) => {
          props.history.push("/");
        })
        .catch((errors) => {
          setServerErrors(errors);
        });
    }
  };

  return (
    <>
      {/* This is a custom header in signIn page */}
      <CustomHeader />
      <Grid
        container
        style={{
          color: "#0000d6",
          background: "#162759",
          height: 663,
        }}
      >
        <Card
          style={{
            maxWidth: 370,
            margin: "0 auto",
            marginTop: 56,
            marginBottom: 70,
            borderRadius: "15px",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              style={{ textAlign: "center", margin: "20px 0px" }}
            >
              Sign In
            </Typography>
            <Form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item>
                  {Object.keys(serverErrors).length > 0 ? (
                    <Alert severity="error">{serverErrors.message}</Alert>
                  ) : null}
                  <Fields.Input
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    required
                    style={{ marginTop: 20, marginBottom: 10, paddingTop: 10 }}
                  />
                  <Fields.Password
                    required
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={handleInputChange}
                    error={errors.password}
                    style={{ margin: "20px 0px", paddingTop: 10 }}
                  />
                  <FormControl>
                    <ReCAPTCHA
                      ref={_reCaptchaRef}
                      sitekey={
                        !window.location.host.includes("xat.x0pa.ai") &&
                        !window.location.host.includes("localhost:3000")
                          ? "6LdiOXUaAAAAACi_XWRNzJmHW-Nawmx-4Ca1kLDI"
                          : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      }
                      onChange={(value) => {
                        handleInputChange({
                          target: { name: "captcha", value },
                        });
                      }}
                    />
                  </FormControl>
                  {/* <Fields.Checkbox
                    name="rememberMe"
                    label="Remember Me"
                    value={values.rememberMe}
                    onChange={handleInputChange}
                    error={errors.rememberMe}
                    style={{ margin: "20px 0px" }}
                  /> */}

                  {/* <div
                    style={{
                      margin: "20px 0px",
                      textAlign: "right",
                      fontSize: "14px",
                    }}
                  >
                    <Link to="/forgot-password">Forgot password?</Link>
                  </div> */}
                  <div style={{ margin: "30px 0px" }}>
                    <Fields.Button
                      type="submit"
                      size="medium"
                      text={
                        isLoading ? <CircularProgress size={20} /> : "Sign In"
                      }
                      disabled={isLoading}
                    />
                  </div>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </Grid>
      <footer
        style={{ textAlign: "center", color: "#737373", margin: "5px 0px" }}
      >
        <PublicFooter 
          handleRedirectMainPage={() => {}}
          currProd="roboroy"
        />
        <div>
          CopyRight 2021 @ X0PA AI Pte Ltd
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, getRText })(withRouter(Signin));
