import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";

export default function SwitchesGroup(props) {
  const { name, label, value, onChange, helperText } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={value}
              onChange={(e) =>
                onChange(convertToDefEventPara(name, e.target.checked))
              }
              name={name}
            />
          }
          label={label}
        />
      </FormGroup>
      <FormHelperText>{helperText || ""}</FormHelperText>
    </FormControl>
  );
}
