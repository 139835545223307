import React from "react";

const PowerBiReports = () => {
  let url = "";
  if (window.location.host.includes("ntu.x0pa.ai")) {
    url =
      "https://app.powerbi.com/reportEmbed?reportId=0e3dd750-d251-4758-ad33-2095bbe39ed8&autoAuth=true&ctid=f4d04404-33d6-4c17-bbd5-de710c6218dc&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWVhc3QtYXNpYS1hLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
  } else {
    url =
      "https://app.powerbi.com/reportEmbed?reportId=da3a1f76-9441-496a-b3de-b732253024cc&autoAuth=true&ctid=f4d04404-33d6-4c17-bbd5-de710c6218dc";
  }
  return (
    <div>
      <iframe
        width="100%"
        height="600"
        src={url}
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default PowerBiReports;
