import {
  SUCCESS_USER_LOGIN,
  ERROR_USER_LOGIN,
  REQUEST_USER_LOGIN,
  LOGOUT,
  REQUEST_LOGS,
  SUCCESS_LOGS,
  ERROR_LOGS,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token") || null,
  isLoading: false,
  user: null,
  logsData: [],
  count: 0,
};

const auth = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_USER_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_USER_LOGIN:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        token: payload.token,
        user: payload.user || payload,
        isLoading: false,
      };
    case ERROR_USER_LOGIN: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case LOGOUT: {
      localStorage.removeItem("token");
      return {
        token: null,
        isLoading: false,
        user: null,
        tutorial_pointer: "yes",
      };
    }
    case REQUEST_LOGS:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_LOGS:
      return {
        ...state,
        logsData: payload && payload.rows,
        count: payload && payload.count,
        isLoading: false,
      };
    case ERROR_LOGS:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default auth;
