import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
// import DatePicker from "./DatePicker";
import Button from "./Button";
import Switch from "./Switch";
import Password from "./Password";
import AutocompleteWithAddField from "./AutocompleteWithAddField";
import RichText from "./RichText";
import MultiSelectAutoComplete from "./MultiSelectAutoComplete";

const Fields = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  // DatePicker,
  Button,
  Switch,
  Password,
  AutocompleteWithAddField,
  RichText,
  MultiSelectAutoComplete,
};

export default Fields;
