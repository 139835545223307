import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { authRoutes } from "../routes/AuthRoutes";
import { matchPath } from "react-router";

const breadcrumbRoutes = authRoutes.filter((x) => x.breadcrumb);

const Breadcrumbs = (props) => {
  const { location } = props;
  const { pathname } = location || {};

  const pathTo = (route) => {
    if (!route.parent) {
      return [route];
    }
    return [...pathTo(route.parent), route];
  };

  const flattenRoutes = (routes) =>
    routes
      .map((route) => [route.routes ? flattenRoutes(route.routes) : [], route])
      .flat(Infinity);

  const route = breadcrumbRoutes.find((x) => !!matchPath(pathname, x.path));

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" style={{ margin: "10px 2px" }}>
      {route &&
        pathTo(route).map((crumb, index, breadcrumbs) => (
          <div key={index} className="item">
            {crumb.routes && (
              <Link
                to={matchPath(pathname, crumb.path).url}
                style={{ textDecoration: "none" }}
              >
                {crumb.name}
              </Link>
            )}
            {!crumb.routes && crumb.name}
          </div>
        ))}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
