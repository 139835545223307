import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.logout();
    this.props.history.push("/signin");
  }
  render() {
    return <div>Logout</div>;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
