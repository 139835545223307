import React from "react";
import { Button } from "@material-ui/core";
function CustomFileUploader({ handleFileChange, chosenFileName, Label }) {
  return (
    <div
      style={{
        marginBottom: "10px",
      }}
    >
      <label
        htmlFor="username"
        style={{
          marginBottom: "-10px",
          color: "#162759",
          fontFamily: "'Manrope', sans-serif",
          fontSize: "15px",
        }}
      >
        {Label}{" "}
      </label>
      <div>
        <Button variant="outlined" color="primary" component="label">
          Upload File
          <input
            type="file"
            name="index"
            onChange={handleFileChange}
            accept=".csv,text/csv"
            hidden
          />
        </Button>
      </div>
      <span
        style={{
          color: "#162759",
          fontFamily: "'Manrope', sans-serif",
          fontSize: "12px",
        }}
      >
        {chosenFileName === "" || chosenFileName === undefined
          ? "No file chosen"
          : chosenFileName && chosenFileName.name.slice(0, 15)}
        {chosenFileName &&
        chosenFileName.name &&
        chosenFileName.name.length > 15
          ? "..."
          : ""}
      </span>
      {/* <span
        style={{
          marginBottom: "10px",
        }}
      >
        'content of button'{" "}
      </span>{" "} */}
    </div>
  );
}
export default CustomFileUploader;
