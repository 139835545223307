import React from "react";
import { Grid, Card, Button, CardContent, Typography } from "@material-ui/core";

function DashBoardheaderButtons({
  handleUploadNewData,
  handleUploadIntStatus,
  handleUploadprogramcode,
}) {
  return (
    <div
      style={{
        marginBottom: "15px",
      }}
    >
      <div
        style={{
          marginBottom: "15px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5">
            <b>Dashboard</b>
          </Typography>
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Card
          style={{
            color: "#2b2b2b",
            borderRadius: 5,
            // marginTop: "-10px",
            // width: "40%",
          }}
        >
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleUploadNewData}
                  style={{
                    marginRight: "12px",
                    color: "white",
                    background: "#1976d2",
                    marginTop: "7px",
                    //   width: "15%",
                  }}
                >
                  Upload New Data
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleUploadIntStatus}
                  style={{
                    marginRight: "12px",
                    color: "white",
                    marginTop: "7px",
                    background: "#1976d2",
                  }}
                >
                  Update Interview Status
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleUploadprogramcode}
                  style={{
                    // marginRight: "12px",
                    color: "white",
                    marginTop: "7px",
                    background: "#1976d2",
                  }}
                >
                  Update Programme Code{" "}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default DashBoardheaderButtons;
