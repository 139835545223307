import { LOGOUT } from "../actions/types";
import { isPublicRoute } from "./common";
import { appendTokenToReq } from "./helpers";

const errorResponse = (actionType) => `ERROR_${actionType}`;
const successResponse = (actionType) => `SUCCESS_${actionType}`;

const createAction = (type, payload) => ({
  type,
  payload,
});

const getUserData = () => {
  try {
    const xuser = localStorage.getItem("xuser");
    if (xuser) {
      return JSON.parse(xuser);
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getLastUserData = () => {
  try {
    const xluser = localStorage.getItem("xluser");
    if (xluser) {
      return JSON.parse(xluser);
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getUserHeaders = () => {
  try {
    let xuser = getUserData();
    let lastUser = false;
    if (!xuser) {
      xuser = getLastUserData();
      lastUser = true;
    }
    const uheaders = {};
    if (xuser) {
      const { userId, companyId } = xuser || {};
      if (!lastUser) {
        uheaders.xhuid = userId || -1;
        uheaders.xhcid = companyId || -1;
      } else {
        uheaders.xhluid = userId || -1;
        uheaders.xhlcid = companyId || -1;
      }
      return uheaders;
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// calls endpoint
const callApi = (params) => {
  const { dispatch, actionType, url, headers, canUseToken, ...otherParams } =
    params;
  if (actionType) dispatch({ type: actionType });
  let finalHeaders = headers;
  if (!finalHeaders) {
    finalHeaders = {};
  }
  const userHeaders = getUserHeaders();
  if (userHeaders) {
    Object.keys(userHeaders).map((key) => {
      finalHeaders[key] = userHeaders[key];
    });
  }
  if (canUseToken) {
    const { token } = appendTokenToReq();
    if (token) {
      if (headers) {
        finalHeaders.xrtoken = token;
      } else {
        finalHeaders = { xrtoken: token };
      }
    }
  }
  const fetchOptions = {
    ...otherParams,
    headers: finalHeaders,
    // credentials: "include",
    mode: "cors",
  };

  return fetch(url, fetchOptions)
    .then((response) => {
      if (actionType) {
        var actionName = actionType.split("REQUEST_")[1];
        var errorResponseType = errorResponse(actionName);
      }
      if (response.ok) {
        if (actionType) {
          var successResponseType = successResponse(actionName);
        }
        if (
          params &&
          params.responseType &&
          params.responseType === "text/html"
        ) {
          return response.text().then((resp) => {
            if (actionType) dispatch(createAction(successResponseType, resp));
          });
        }
        if (
          params &&
          params.responseType &&
          params.responseType === "application/pdf"
        ) {
          const ctype = response.headers.get("Content-Type");
          if (ctype.includes("application/json")) {
            return response.json().then((resp) => {
              if (actionType) dispatch(createAction(errorResponseType, resp));
              return Promise.resolve(resp);
            });
          }
          return response.blob().then((resp) => {
            if (actionType) dispatch(createAction(successResponseType, resp));
            return Promise.resolve(resp);
          });
        }
        return response.json().then((resp) => {
          if (actionType) dispatch(createAction(successResponseType, resp));
          return Promise.resolve(resp);
        });
      }
      return response
        .json()
        .then((resp) => {
          return Promise.reject(resp);
        })
        .catch((resp) => {
          return Promise.reject(resp);
        });
    })
    .catch((err) => {
      if (err.code && err.code === "xemp-1") {
        dispatch(createAction(LOGOUT));
      } else {
        if (actionType) {
          const actionName = actionType.split("REQUEST_")[1];
          const errorResponseType = errorResponse(actionName);
          dispatch(createAction(errorResponseType, err));
        }
      }
      return Promise.reject(err);
    });
};

export default callApi;
